/* You can add global styles to this file, and also import other style files */

.top-buffer-10 {
  margin-top: 10px;
}


@media print {
  body {
    visibility: hidden;
  }
}

.clickable {
  cursor: pointer;
}
